<template>
  <v-container>
    <v-card rounded>
      <v-card-text>
        <v-row>
          <v-col>
            <div class="text-center headline">{{$t("CHOOSE_ONE")}}</div>
            <br/>
            <v-btn block color="primary" @click="onSession">{{$t('START_SESSION')}}</v-btn>
            <br/>
            <div class="text-center headline">{{$t("OR")}}</div>
            <br/>
            <v-btn block color="warning" @click="onLeave">{{$t('APPLY_LEAVE')}}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Choice",
  methods: {
    onSession() {
      this.$router.push('/session/start');
    },
    onLeave() {
      this.$router.push('/leave/apply');
    }
  }
}
</script>

<style scoped>

</style>

<i18n>
{
  "en": {
    "CHOOSE_ONE": "Choose one:",
    "START_SESSION": "Start a session",
    "APPLY_LEAVE": "Apply Leave",
    "OR": "or"
  },
  "zh-tw": {
    "CHOOSE_ONE": "請選擇：",
    "START_SESSION": "建立工作時段"
  }
}
</i18n>
